import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import Nft from '../types/nftType';
import { GetNftsResponseBody } from './types/getNftsType';
import { nftValidator } from './validators/nftValidator';
import { AxiosResponse } from 'axios';

/**
 * NFT一覧取得APIを呼び出す
 * @returns {Nft[]} NFT情報一覧
 * @throws {Error} API呼び出しに失敗した場合にエラーを投げる
 */
export const getNfts = async (): Promise<Nft[]> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(API_PATHS.GET_NFTS);
        const responseBody: GetNftsResponseBody = response.data ?? {};

        const nfts: Array<Nft> = [];

        for (const nft of responseBody.nft_list) {
            const convertedNft: Nft = {
                imageUrl: nft.image_url,
                hasPostedDiary: nft.has_posted_diary,
                hasPostedPicture: nft.has_posted_picture,
                event: {
                    id: nft.event.id,
                    name: nft.event.name,
                    color: nft.event.color,
                    mainTextColor: nft.event.main_text_color,
                    subTextColor: nft.event.sub_text_color,
                    shortName: nft.event.short_name,
                    date: nft.event.date,
                    canPostTrajectory: nft.event.can_post_trajectory,
                },
            } as Nft;

            const isValid: boolean = nftValidator(convertedNft);
            if (!isValid) {
                continue;
            }

            nfts.push(convertedNft);
        }
        return nfts ?? [];
    } catch (error) {
        console.error('Error fetching nfts:', error);
        throw error;
    }
};
